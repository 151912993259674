<template>
  <div class="box">
    <div class="header">
      <div class="logo">
        {{ $t("common.systemName") }}
        <span class="version">{{ version }}</span>
      </div>
      <!-- <el-dropdown class="showLang">
        <span>{{lang}}<i class="el-icon-arrow-down el-icon--right"></i></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in langOptions" :key="item.value" @click.native="chooseLang(item)"> {{ item.label }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-position="left"
      label-width="0px"
      class="demo-ruleForm login-container"
    >
      <h2
        class="title"
        style="color: #3c8dbc"
      >
        {{ $t("login.title") }}
      </h2>
      <el-form-item prop="username">
        <el-input
          v-model="ruleForm.username"
          type="text"
          autofocus
          :placeholder="$t('common.account')"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="ruleForm.password"
          type="password"
          :placeholder="$t('common.password')"
          show-password
        />
      </el-form-item>
      <el-checkbox
        v-model="isRember"
        class="remember"
      >
        {{ $t("login.rememberPassword") }}
      </el-checkbox>
      <el-form-item style="width: 100%">
        <el-button
          type="primary"
          style="width: 100%"
          :loading="logining"
          @click="login"
        >
          {{ $t("login.login") }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { login, get_version } from '../api/api.js'
import { mapMutations } from 'vuex'
export default {
  name: 'Login',
  data () {
    return {
      version: '',
      logining: false,
      ruleForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [{ required: true, message: this.$t('login.inputAccount'), trigger: 'blur' }],
        password: [{ required: true, message: this.$t('common.inputPassword'), trigger: 'blur' }]
      },
      isRember: false, // 是否勾选了记住密码选项
      lang: 'Language/中文',
      langOptions: [
        {
          value: 'zh',
          label: 'Language/中文'
        },
        {
          value: 'en',
          label: 'Language/English'
        }
      ]
    }
  },
  created () {
    this.getVersion()
    if (localStorage.lang === 'en') {
      this.lang = 'Language/English'
    } else {
      this.lang = 'Language/中文'
    }
    if (localStorage.isRember) {
      this.getAccount()
    }
    // 添加监听回车按键
    document.addEventListener('keyup', this.watchEnter)
  },
  destroyed () {
    // 移除监听回车按键
    document.removeEventListener('keyup', this.watchEnter)
  },
  methods: {
    ...mapMutations(['addToken', 'addSAID', 'addUsername', 'addPassword', 'addRoleRight', 'updateVersion']),
    async getVersion () {
      const res = await get_version()
      if (res.data.code === 0) {
        this.version = res.data.data.version
        this.updateVersion(this.version)
      }
    },
    chooseLang (lang) {
      if (localStorage.lang == lang.value) return
      localStorage.lang = lang.value
      this.lang = lang.label
      location.reload()
    },
    login () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.logining = true
          login({
            username: this.ruleForm.username,
            password: this.ruleForm.password
          })
            .then((res) => {
              if (res.data.code === 0) {
                this.addToken(res.data.data.Token)
                this.addSAID(res.data.data.SAID)
                this.addUsername(res.data.data.LoginName)
                this.addPassword(this.ruleForm.password)
                this.addRoleRight(res.data.data.RoleRight)
                localStorage.token = res.data.data.Token
                localStorage.said = res.data.data.SAID
                localStorage.loginName = res.data.data.LoginName
                localStorage.roleRight = res.data.data.RoleRight
                if (this.isRember) {
                  localStorage.isRember = this.isRember
                  localStorage.username = this.ruleForm.username
                  localStorage.password = window.btoa(this.ruleForm.password)
                } else {
                  localStorage.isRember = ''
                  localStorage.username = ''
                  localStorage.password = ''
                }
                if (this.lang === 'English/English') {
                  localStorage.lang = 'en'
                } else {
                  localStorage.lang = 'zh'
                }
                this.$router.push('/')
                this.$message.success(this.$t('login.loginSuccess'))
              }
              this.logining = false
            })
            .catch((res) => {
              this.logining = false
              this.$message.error(res)
            })
        } else {
          this.$message.error(this.$t('login.check'))
          return false
        }
      })
    },
    // 回车直接调用登录事件
    watchEnter (e) {
      const keyNum = window.event ? e.keyCode : e.which
      if (keyNum == 13) {
        this.login()
      }
    },
    // 读取本地的账号密码
    getAccount () {
      this.ruleForm.username = localStorage.username
      this.ruleForm.password = window.atob(localStorage.password)
      this.isRember = true
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 16px 40px 16px 40px;
  background-color: #3c8dbc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    color: #fff;
    font-size: 24px;
    font-family: 'Kaushan Script', cursive;
    font-weight: 500;
  }
}
.lang {
  position: absolute;
  top: 12px;
  right: 20px;
}
.box {
  background-color: #f3f5f7;
  background-size: cover;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.login-container {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin: 180px auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 15px #cac6c6;
  .title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
  }
  .remember {
    margin: 0px 0px 35px 0px;
  }
}
.showLang {
  color: #fff;
  margin-left: 20px;
}
.version {
  margin-left: 5px;
  font-size: 14px;
}
.login-container /deep/ .el-input {
  width: 100%;
}
</style>
